import { Chat, Conversation } from "../types";
import axiosInstance from "./axiosInstance";

export const startNewChat = async (conversationData: {
  focus_ids: string[];
  social_media_ids: string[];
}): Promise<Conversation> => {
  try {
    const response = await axiosInstance.post(
      "/ask_moodee/new_conversation",
      conversationData,
    );
    return response.data;
  } catch (error: any) {
    // Type assertion
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating chat");
    }
  }
};

export const getAllConversationsByUser = async (): Promise<Conversation[]> => {
  try {
    const response = await axiosInstance.get("/ask_moodee/conversations");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversations");
    }
  }
};

export const getConversationHistory = async (
  conversationId: string,
): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get(
      `/ask_moodee/history`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversation history");
    }
  }
};

export const postFirstMessage = async (
  conversation_id: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(
      "/ask_moodee/first_message",
      { conversation_id }, // Envoi d'un objet contenant conversation_id
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching first message");
    }
  }
};

export const askQuestionToMoodee = async (
  conversation_id: string,
  content: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(
      "/ask_moodee/",
      { conversation_id, content }, // Envoi d'un objet contenant conversation_id
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching first message");
    }
  }
};

export const deleteConversation = async (conversationId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/ask_moodee/delete_conversation`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting  history");
    }
  }
};
