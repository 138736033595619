import React, { useState } from "react";
import { login } from "../../lib/auth";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import AuthLayout from "./AuthLayout";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await login(email, password);
    } catch (err: any) {
      console.error("Login failed with error:", err);
      if (err.detail && typeof err.detail === "object") {
        setError(err.detail.msg || "Login failed");
      } else if (typeof err.detail === "string") {
        setError(err.detail);
      } else {
        setError("Login failed. Please try again.");
      }
    }
  };

  return (
    <AuthLayout authAction="Se connecter">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
          <div className="relative">
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="email">
              Email
            </Label>
          </div>

          <div className="relative">
            <Input
              type="password"
              id="password"
              className="font-medium"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="password">
              Mot de passe
            </Label>
          </div>
        </div>

        <div className="my-4 flex w-full justify-between text-sm">
          <div className="flex items-center gap-2">
            <Checkbox id="rememberMe" />
            <span>Se souvenir de moi</span>
          </div>
          <a href="/" className="text-orange">
            Mot de passe oublié
          </a>
        </div>

        <Button type="submit" hover="jumping" size="full">
          Se connecter
        </Button>

        <div className="mt-4 text-center text-sm">
          <p>
            Vous n’avez pas de compte ?{" "}
            <a href="/auth/signup" className="text-orange">
              Inscrivez-vous
            </a>
          </p>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
