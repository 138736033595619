import React from "react";

// Définition des types pour les props
interface AvatarProps {
  firstName: string | undefined;
  lastName: string | undefined;
}

const Avatar: React.FC<AvatarProps> = ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return null;
  }
  const initials = `${firstName[0]}${lastName[0]}`.toUpperCase();

  return (
    <div className="flex size-6 items-center justify-center rounded-full bg-green text-xs font-bold text-white">
      {initials}
    </div>
  );
};

export default Avatar;
