import { Language } from "../types";
import axiosInstance from "./axiosInstance";

// Function to get all focuses
export const getAllLanguages = async (): Promise<Language[]> => {
  try {
    const response = await axiosInstance.get<Language[]>("/language");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch languages",
      );
    } else {
      throw new Error("Failed to fetch languages");
    }
  }
};
