import * as React from "react";
import { cn } from "../../lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onSubmit?: (e: any) => void; // Prop pour déclencher la soumission
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, onKeyDown, onSubmit, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; // Reset height to auto to calculate new height
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scroll height
      }
    };

    React.useEffect(() => {
      if (textareaRef.current) {
        handleInput(); // Adjust the height on first render in case there's already content
      }
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent default Enter behavior (newline)
        if (onSubmit) {
          onSubmit(e); // Trigger form submission
          if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to auto to calculate new height
          }
        }
      }
      if (onKeyDown) {
        onKeyDown(e); // Propagate event to any other onKeyDown handler
      }
    };

    return (
      <textarea
        className={cn(
          "placeholder:text-muted-foreground flex w-full bg-transparent text-sm disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={(el) => {
          textareaRef.current = el;
          if (typeof ref === "function") ref(el);
          else if (ref) ref.current = el;
        }}
        rows={1} // Set initial height to 1 row
        onInput={handleInput} // Adjust height dynamically
        onKeyDown={handleKeyDown} // Handle Enter for submit
        {...props}
      />
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };
