import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconTool,
} from "@tabler/icons-react";
import {
  ChartColumnBigIcon,
  Loader,
  PlusIcon,
  Search,
  SendIcon,
  Trash,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";
import {
  askQuestionToMoodee,
  deleteConversation,
  getAllConversationsByUser,
  getConversationHistory,
  postFirstMessage,
  startNewChat,
} from "../lib/askMoodee";
import { getAllFocusesByUser } from "../lib/focus";
import { getAllSocialMedias } from "../lib/socialMedias";
import { useDebounce } from "../lib/useDebounce";
import { cn, formatDateForChat, highlightText, typeWriter } from "../lib/utils";
import { Chat, Conversation, Focus, SocialMedia } from "../types";
import Avatar from "./Avatar";
import ChatConversation from "./ChatConversation";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { MultiSelect } from "./ui/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Separator } from "./ui/separator";
import { Textarea } from "./ui/textarea";

const ChatPage: React.FC = () => {
  const { user } = useUser();
  const [searchConversation, setSearchConversation] = useState<string>("");
  const [searchChat, setSearchChat] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);
  const [answerLoading, setAnswerLoading] = useState<boolean>(false);
  const [, setError] = useState<string | null>(null);
  const [focuses, setFocuses] = useState<Focus[]>([]);
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);
  const [selectedFocus, setSelectedFocus] = useState<string>();
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string[]>([]);
  const [currentConversation, setCurrentConversation] = useState<Chat[] | null>(
    null,
  );
  const [filteredChats, setFilteredChats] = useState<Chat[] | null>(null);
  // Track selected conversation
  const [chatContent, setChatContent] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const debouncedSearchConversation = useDebounce(searchConversation, 500);
  const debouncedSearchChat = useDebounce(searchChat, 500);

  // Apply filtering to chats based on debounced search term
  useEffect(() => {
    if (debouncedSearchChat.trim() === "") {
      setFilteredChats(currentConversation);
    } else {
      const filtered = currentConversation?.filter((chat) =>
        chat.content.toLowerCase().includes(debouncedSearchChat.toLowerCase()),
      );
      setFilteredChats(filtered || []);
    }
  }, [debouncedSearchChat, currentConversation]);

  // Apply filtering to conversations based on debounced search term
  useEffect(() => {
    if (debouncedSearchConversation.trim() === "") {
      setFilteredConversations(conversations);
    } else {
      const filtered = conversations.filter((conversation) =>
        conversation.focus_names.some((focus) =>
          focus
            .toLowerCase()
            .includes(debouncedSearchConversation.toLowerCase()),
        ),
      );
      setFilteredConversations(filtered);
    }
  }, [debouncedSearchConversation, conversations]);

  useEffect(() => {
    const loadFocuses = async () => {
      try {
        const data = await getAllFocusesByUser();
        setFocuses(data);
        setFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setLoading(false);
      }
    };

    loadFocuses();
  }, []);

  useEffect(() => {
    const loadSocialMedias = async () => {
      try {
        const data = await getAllSocialMedias();
        setSocialMedias(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch social media platforms.");
      }
    };

    loadSocialMedias();
  }, []);

  useEffect(() => {
    const loadConversations = async () => {
      try {
        const data = await getAllConversationsByUser();
        setConversations(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch social media platforms.");
      }
    };

    loadConversations();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (currentConversation) {
        scrollToBottom();
      }
    }, 100);
  }, [currentConversation]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleStartChat = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFocus || selectedSocialMedia.length === 0) {
      setError("Please select a focus and a social media platform.");
      return;
    }

    try {
      setLoading(true);

      const conversationData = {
        focus_ids: [selectedFocus],
        social_media_ids: selectedSocialMedia,
      };

      // 1. Create a new conversation
      const newConversation = await startNewChat(conversationData);

      // 2. Set the newly created conversation in the state
      const updatedConversations = await getAllConversationsByUser();
      setConversations(updatedConversations);

      // 4. Get the first message of the conversation
      const firstMessage = await postFirstMessage(
        newConversation.conversation_id,
      );

      // Update the current conversation state with the first message
      setCurrentConversation([firstMessage]);

      // 5. Now handle the typewriter effect for the first message
      const holderId = `txt-holder-${firstMessage.chat_id}`;

      setTimeout(() => {
        const holderElement = document.getElementById(holderId);
        if (holderElement) {
          // Clear content before typing
          holderElement.innerHTML = "";

          const textPosition = 0;
          const speed = 10; // Adjust speed as needed

          typeWriter(
            [firstMessage.content],
            textPosition,
            speed,
            holderElement,
          );
        }
      }, 100); // Small delay to ensure the element is in the DOM

      setLoading(false); // Stop loading after everything is set
      setSelectedFocus("");
      setSelectedSocialMedia([]);
    } catch (error) {
      setError("Failed to start a new conversation.");
      setLoading(false);
      setAnswerLoading(false); // Ensure we stop loading even on failure
    }
  };

  const handleConversationSelect = async (conversationId: string) => {
    try {
      const data = await getConversationHistory(conversationId);
      setCurrentConversation(data);
    } catch (err: any) {
      setError(err.message || "Failed to fetch conversation.");
    }
  };

  const handlePostMessageOnChat = (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentConversation) {
      setError("No conversation selected.");
      return;
    }

    // 1. Ajout immédiat du message de l'utilisateur à l'interface
    const newMessage: Chat = {
      chat_id: crypto.randomUUID(), // Génère un ID temporaire
      conversation_id: currentConversation[0].conversation_id,
      content: chatContent,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      favorite: false,
      author: "User",
    };

    setCurrentConversation((prevConversation) =>
      prevConversation ? [...prevConversation, newMessage] : [newMessage],
    );
    setChatContent(""); // Réinitialiser l'input après le submit
  };

  const handleGetMoodeeAnswer = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentConversation) {
      setError("No conversation selected.");
      return;
    }

    try {
      setAnswerLoading(true); // Set answerLoading to true immediately

      const answer = await askQuestionToMoodee(
        currentConversation[0].conversation_id,
        chatContent,
      );

      // Append Moodee's answer to the conversation
      setCurrentConversation((prevConversation) =>
        prevConversation ? [...prevConversation, answer] : [answer],
      );

      // Scroll to bottom
      setAnswerLoading(false);
      // Delay for a short period to ensure DOM updates
      setTimeout(() => {
        const holderId = `txt-holder-${answer.chat_id}`;
        const holderElement = document.getElementById(holderId);

        if (holderElement) {
          const textPosition = 0;
          const speed = 10;
          holderElement.innerHTML = ""; // Clear the content

          // Start the typewriter effect
          typeWriter([answer.content], textPosition, speed, holderElement);
        }
      }, 100); // Small delay to ensure DOM element exists
    } catch (err: any) {
      setError("Failed to fetch latest message.");
      setAnswerLoading(false);
    }
  };

  const handleDeleteConversation = async () => {
    if (!currentConversation) {
      setError("No conversation selected.");
      return;
    }

    try {
      // 1. Supprimer la conversation de la base de données
      await deleteConversation(currentConversation[0].conversation_id);
      // 2. Supprimer la conversation de l'interface
      setConversations((prevConversations) =>
        prevConversations.filter(
          (conv) =>
            conv.conversation_id !== currentConversation[0].conversation_id,
        ),
      );
      // 3. Réinitialiser l'état de la conversation actuelle
      setCurrentConversation(null);
    } catch (err: any) {
      setError("Failed to delete conversation.");
    }
  };

  const selectedConversation = conversations.find(
    (conv) => conv.conversation_id === currentConversation?.[0].conversation_id,
  );

  return (
    <div className="relative h-full p-4">
      <div className="relative z-50 flex h-full">
        <div className="flex h-full w-52 flex-col rounded-5xl bg-blue-light p-4">
          <h1 className="mx-auto mb-6 pt-3 text-2xl font-bold text-blue">
            Chat
          </h1>
          <Button
            className="flex w-full gap-3 bg-white px-3 font-light text-blue"
            hover="jumping"
            onClick={() => {
              setCurrentConversation(null);
            }}
          >
            <PlusIcon className="size-5" />
            <p className="text-sm">Nouvelle discussion</p>
          </Button>
          <div className="mt-2 flex items-center justify-between gap-2">
            <div
              className={cn(
                "flex w-full items-center rounded-full bg-white px-4",
              )}
            >
              <Search className="text-blue" />
              <Input
                placeholder="Rechercher..."
                className="w-full rounded-full border-none py-2 text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
                value={searchConversation}
                onChange={(e) => setSearchConversation(e.target.value)}
              />
            </div>
            {/*             <Button
              className="w-fit gap-2 bg-white p-2 font-light text-blue"
              hover="jumping"
            >
              <FilterIcon className="size-5" />
            </Button> */}
          </div>

          <div className="mt-8 flex-1 overflow-y-auto">
            {/* Utiliser la fonction utilitaire pour chaque période */}
            <ul className="flex flex-col gap-2">
              <ChatConversation
                title="Aujourd'hui"
                conversations={filteredConversations}
                checkYesterday={true}
                handleConversationSelect={handleConversationSelect}
                selectedConversation={selectedConversation?.conversation_id}
              />

              <ChatConversation
                title="7 derniers jours"
                conversations={filteredConversations}
                checkLastWeek={true}
                handleConversationSelect={handleConversationSelect}
                selectedConversation={selectedConversation?.conversation_id}
              />

              <ChatConversation
                title="Depuis le début"
                conversations={filteredConversations}
                checkOlderThanWeek={true} // Add this prop to filter conversations older than a week
                handleConversationSelect={handleConversationSelect}
                selectedConversation={currentConversation?.[0].conversation_id}
              />
            </ul>
          </div>
        </div>
        <div className="mx-auto flex size-full flex-col justify-between p-4">
          <form
            onSubmit={handleStartChat}
            className={cn(
              currentConversation
                ? "hidden"
                : "mx-auto mt-36 flex w-11/12 max-w-[600px] flex-col gap-5 rounded-3xl bg-white p-16 shadow-full",
            )}
          >
            <h3 className="mx-auto text-2xl font-semibold">
              Choisis ton produit et ton media
            </h3>

            <Select onValueChange={setSelectedFocus} value={selectedFocus}>
              <SelectTrigger className="flex w-full items-center justify-between rounded-full border border-gray-100 bg-inherit p-6 text-sm capitalize text-gray-500 hover:bg-inherit">
                <SelectValue placeholder="Produit" />
              </SelectTrigger>
              <SelectContent>
                {focuses.map((focus) => (
                  <SelectItem
                    key={focus.focus_id}
                    value={focus.focus_id}
                    className="focus:bg-orange-light"
                  >
                    {focus.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <MultiSelect
              options={socialMedias.map((social) => ({
                label: social.name,
                value: social.social_media_id,
                icon: (() => {
                  if (social.formatted_name === "tiktok") {
                    return IconBrandTiktok;
                  } else if (social.formatted_name === "instagram") {
                    return IconBrandInstagram;
                  } else {
                    return IconTool;
                  }
                })(),
              }))}
              onValueChange={setSelectedSocialMedia}
              defaultValue={selectedSocialMedia}
              placeholder="Media"
              variant="orange"
              animation={2}
              maxCount={3}
            />

            <Button
              disabled={loading}
              type="submit"
              className="mx-auto w-fit rounded-lg px-5"
              hover="jumping"
            >
              {loading ? (
                <Loader className="size-5 animate-spin" />
              ) : (
                "Démarrer la conversation"
              )}
            </Button>
          </form>

          <div
            className={cn(
              currentConversation
                ? "mx-auto flex h-12 items-center gap-6 text-lg font-semibold lg:gap-8"
                : "hidden",
            )}
          >
            <h1 className="flex h-full items-center overflow-x-auto whitespace-nowrap rounded-xl bg-blue-light px-6 py-3 leading-5">
              {selectedConversation?.focus_names.join(", ")}
            </h1>
            <Separator orientation="vertical" />

            <div className="flex h-full items-center gap-2 rounded-xl bg-green-light px-6 py-3">
              {selectedConversation?.social_media_names.map((social) => (
                <div key={social}>
                  {social === "Custom" && <IconTool size={24} />}
                  {social === "Instagram" && <IconBrandInstagram size={24} />}
                  {social === "TikTok" && <IconBrandTiktok size={24} />}
                </div>
              ))}
            </div>
            <Separator orientation="vertical" />
            <div
              className={cn(
                "flex h-full w-fit items-center rounded-xl bg-blue-light px-4",
              )}
            >
              <Search className="text-dark" />
              <Input
                placeholder="Rechercher..."
                className="w-full rounded-xl border-none text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
                value={searchChat}
                onChange={(e) => setSearchChat(e.target.value)}
              />
            </div>
            <Separator orientation="vertical" />
            <Button
              className="h-full rounded-xl bg-orange"
              onClick={handleDeleteConversation}
            >
              <Trash className="size-5" />
            </Button>
          </div>
          <Separator
            orientation="horizontal"
            className={cn(
              currentConversation
                ? "mx-auto my-5 w-11/12 xl:w-10/12 2xl:w-9/12"
                : "hidden",
            )}
          />

          <div className="my-5 flex flex-1 flex-col-reverse overflow-y-scroll">
            {/* Container for messages that starts at the bottom and grows upwards */}
            <div className="mx-auto flex w-11/12 flex-col gap-4 xl:w-10/12 2xl:w-9/12">
              {filteredChats?.map((chat) => (
                <div
                  key={chat.chat_id}
                  className={cn(
                    chat.author !== "Moodee"
                      ? "ml-auto flex justify-end rounded-3xl bg-white px-5 py-4 shadow-full"
                      : "mr-auto",
                    "flex w-fit max-w-[90%] flex-col",
                  )}
                >
                  <div
                    className={cn(
                      chat.author !== "Moodee" && "flex-row-reverse",
                      "mb-2 flex items-center gap-4",
                    )}
                  >
                    {chat.author === "Moodee" ? (
                      <img
                        src="/moodee-v1.png"
                        alt="logo"
                        className="size-6 rounded-full"
                      />
                    ) : (
                      <Avatar
                        firstName={user?.first_name}
                        lastName={user?.last_name}
                      />
                    )}
                    <div className="my-auto flex gap-2">
                      <p className="text-sm font-semibold">
                        {chat.author === "Moodee"
                          ? "Moodee"
                          : `${user?.first_name} ${user?.last_name}`}
                      </p>
                      <div>
                        <Separator orientation="vertical" />
                      </div>
                      <p className="text-sm font-light text-gray-500">
                        {formatDateForChat(chat.created_at)}
                      </p>
                    </div>
                  </div>
                  <div
                    id={`txt-holder-${chat.chat_id}`}
                    className={cn("chat-response text-justify text-sm")}
                    dangerouslySetInnerHTML={{
                      __html: highlightText(chat.content, searchChat),
                    }}
                  ></div>
                </div>
              ))}
              {answerLoading && (
                <div>
                  <span id="blinker"></span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handlePostMessageOnChat(e);
              handleGetMoodeeAnswer(e);
            }}
            className={cn(
              (currentConversation === null || answerLoading) && "opacity-50",
              "mx-auto mb-10 flex max-h-64 w-11/12 flex-col rounded-2xl bg-white p-4 shadow-full xl:w-10/12 2xl:w-9/12",
            )}
          >
            <Textarea
              placeholder="Tu as une question ?"
              className="mb-2 border-none px-1 py-0 placeholder:italic focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
              disabled={currentConversation === null || answerLoading}
              value={chatContent}
              onChange={(e) => setChatContent(e.target.value)}
              onSubmit={(e) => {
                e.preventDefault();
                handlePostMessageOnChat(e); // Appeler la soumission du formulaire
                handleGetMoodeeAnswer(e); // Gérer la réponse
              }}
            />
            <Separator orientation="horizontal" />
            <div className="mt-2 flex h-fit items-center justify-between">
              <Link
                to="/products"
                className="flex items-center justify-around gap-4 rounded-lg px-3 py-2 text-sm font-normal shadow-full"
              >
                <ChartColumnBigIcon className="size-5" />
                Voir les statistiques du produit
              </Link>
              <Button
                type="submit"
                className="rounded-lg px-8"
                disabled={currentConversation === null || answerLoading}
              >
                <SendIcon className="size-5" />
              </Button>
            </div>
          </form>
        </div>
      </div>
      <img
        alt="shadow"
        src="chat-shadow.png"
        className="absolute bottom-0 right-0 z-0 hidden blur-[80px] md:block"
      />
    </div>
  );
};

export default ChatPage;
