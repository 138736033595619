import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";

const inputVariants = cva("text-base", {
  variants: {
    design: {
      default:
        "flex w-full px-4 py-3 border border-blue rounded-full focus:outline-none focus:ring-1  focus:ring-blue bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue focus-visible:bg-transparent disabled:cursor-not-allowed disabled:opacity-50",
    },
  },
  defaultVariants: {
    design: "default",
  },
});

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = "text", ...props }: InputProps, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ design: props.design }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

export { Input };
