import { ChevronRight, PlusIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";
import {
  addFocusToUser,
  getAllFocuses,
  getAllFocusesByUser,
} from "../lib/focus";
import { cn, createSlug } from "../lib/utils";
import { Focus } from "../types";
import Header from "./Header";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog"; // Import modal components
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const Products: React.FC = () => {
  const { languages } = useUser();
  const [allFocuses, setAllFocuses] = useState<Focus[]>([]);
  const [myFocuses, setMyFocuses] = useState<Focus[]>([]);

  const [selectedFocus, setSelectedFocus] = useState<string | null>(null);
  const [filteredFocuses, setFilteredFocuses] = useState<Focus[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage modal

  useEffect(() => {
    const loadAllFocuses = async () => {
      try {
        const data = await getAllFocuses();
        setAllFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setLoading(false);
      }
    };

    loadAllFocuses();
  }, []);

  useEffect(() => {
    const loadMyFocuses = async () => {
      try {
        const data = await getAllFocusesByUser();
        setMyFocuses(data);
        setFilteredFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setLoading(false);
      }
    };

    loadMyFocuses();
  }, []);

  const handleAddFocus = async () => {
    if (selectedFocus) {
      try {
        await addFocusToUser(selectedFocus);
        alert("Focus added successfully!");
        window.location.reload(); // Refresh the page after adding a focus
      } catch (error: any) {
        alert("Failed to add focus.");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredFocuses(myFocuses);
    } else {
      const filtered = myFocuses.filter((focus) =>
        focus.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredFocuses(filtered);
    }
  }, [searchQuery, myFocuses]);

  const hoverColors = [
    "hover:bg-green-light",
    "hover:bg-orange-light",
    "hover:bg-purple-light",
  ];

  const buttonColors = ["bg-green", "bg-orange", "bg-purple"];

  // Return both hover and base colors as a string
  const getColorClass = (index: number) => {
    const hoverColor = hoverColors[index % hoverColors.length];
    const buttonColor = buttonColors[index % buttonColors.length];
    return { hoverColor, buttonColor };
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="p-8">
      <Header
        title="Produits"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        languages={languages}
      />

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogTrigger asChild>
          <Button className="ml-auto mt-12 flex rounded-lg font-normal">
            <PlusIcon size={20} className="mr-2" />
            Ajouter un produit
          </Button>
        </DialogTrigger>
        <DialogContent>
          <div className="flex h-32 flex-col justify-around gap-10 rounded-xl p-3">
            <Select onValueChange={(value) => setSelectedFocus(value)}>
              <SelectTrigger className="flex w-full items-center justify-between rounded-full border border-orange-light bg-inherit p-6 text-sm capitalize text-dark hover:bg-inherit">
                <SelectValue placeholder="Choisir un nouveau focus" />
              </SelectTrigger>
              <SelectContent>
                {allFocuses.map((focus) => (
                  <SelectItem
                    key={focus.focus_id}
                    value={focus.focus_id}
                    className="focus:bg-orange-light"
                  >
                    {focus.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              className="mx-auto w-fit rounded-full bg-orange px-8 text-dark"
              hover="jumping"
              onClick={handleAddFocus}
              disabled={!selectedFocus}
            >
              Add Focus
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="mt-12">
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {filteredFocuses.map((focus, index) => {
            const colorClasses = getColorClass(index); // Get hover and base color
            return (
              <li
                key={focus.focus_id}
                className={cn(
                  "col-span-1 flex h-36 flex-col justify-between rounded-3xl p-4 shadow-full transition-all duration-300",
                  "bg-white", // Default base color
                  colorClasses.hoverColor, // Hover and button color
                )}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-xs font-light">
                    {new Date(focus.created_at).toLocaleDateString()}
                  </p>
                  <h1 className="text-lg font-bold leading-5">{focus.name}</h1>
                </div>
                <Link
                  to={`/focus/${createSlug(focus.name)}`}
                  state={{ focusId: focus.focus_id }}
                  className={cn(
                    "w-fit rounded-full p-2 text-white shadow-none",
                    colorClasses.buttonColor,
                  )}
                >
                  <ChevronRight size={20} />
                </Link>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Products;
