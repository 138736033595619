import { SocialMedia } from "../types";
import axiosInstance from "./axiosInstance";

// Function to get all focuses
export const getAllSocialMedias = async (): Promise<SocialMedia[]> => {
  try {
    const response = await axiosInstance.get<SocialMedia[]>("/social_media");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch social medias",
      );
    } else {
      throw new Error("Failed to fetch social medias");
    }
  }
};
