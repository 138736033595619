import React from "react";
import { useUser } from "../context/UserContext";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

const Settings: React.FC = () => {
  const { user } = useUser();

  return (
    <div className="p-10">
      <h1 className="mb-10 text-3xl font-bold text-blue">Paramètres</h1>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-5 rounded-3xl border border-blue p-4">
          <h1 className="text-xl font-semibold">Informations personnelles</h1>
          <div className="flex flex-col gap-x-2 gap-y-4">
            <div className="relative">
              <Input type="text" id="firstname" value={user?.first_name} />
              <Label design="auth" htmlFor="firstname">
                Prénom
              </Label>
            </div>
            <div className="relative">
              <Input type="text" id="lastname" value={user?.last_name} />
              <Label design="auth" htmlFor="lastname">
                Nom
              </Label>
            </div>
            <div className="relative col-span-2">
              <Input type="email" id="email" value={user?.email} required />
              <Label design="auth" htmlFor="email">
                Email
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
