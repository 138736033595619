import { User } from "../types";
import axiosInstance from "./axiosInstance"; // Import the axios instance

// Function to create a new user (Sign Up)
export const signup = async (userData: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}) => {
  try {
    const response = await axiosInstance.post("/auth/signup", userData);
    return response.data;
  } catch (error: any) {
    // Type assertion
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating user");
    }
  }
};

// Function to log in a user
export const login = async (email: string, password: string) => {
  try {
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);

    const response = await axiosInstance.post("/auth/login", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.data.access_token) {
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
    }

    window.location.href = "/"; // Redirect to the home page
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error("Login failed");
  }
};

// Function to get the current user's info
export const getUserInfo = async (): Promise<User> => {
  try {
    const response = await axiosInstance.get("/user/me");
    return response.data;
  } catch (error: any) {
    throw error.response
      ? error.response.data
      : new Error("Failed to fetch user info");
  }
};

// Function to refresh the token
export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axiosInstance.post("/auth/refresh-token", {
      refresh_token: refreshToken,
    });
    if (response.data.access_token) {
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
    }
    return response.data;
  } catch (error: any) {
    throw error.response
      ? error.response.data
      : new Error("Token refresh failed");
  }
};

export const logout = () => {
  // Clear the tokens from local storage
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // Optionally, you can also clear other user-related data from local storage
  // Redirect to the login page
  window.location.href = "/auth/login";
};
