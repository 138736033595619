import React, { useState } from "react";
import { login, signup } from "../../lib/auth";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import AuthLayout from "./AuthLayout";

const SignUp: React.FC = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    try {
      const userData = {
        first_name: firstname,
        last_name: lastname,
        email,
        password,
      };

      // Step 1: Create the user
      await signup(userData);

      // Step 2: Log in the user
      await login(email, password);

      // Step 3: Fetch user data after login and store it in state
    } catch (err: any) {
      setError(err.msg || "An error occurred");
    }
  };

  return (
    <AuthLayout authAction="S'inscrire">
      <form onSubmit={handleSubmit}>
        {error && <p className="mb-3 text-red-500">{error}</p>}

        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <div className="relative">
            <Input
              type="text"
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="firstname">
              Prénom
            </Label>
          </div>
          <div className="relative">
            <Input
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="lastname">
              Nom
            </Label>
          </div>
          <div className="relative col-span-2">
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="email">
              Email
            </Label>
          </div>

          <div className="relative col-span-2">
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="password">
              Mot de passe
            </Label>
          </div>

          <div className="relative col-span-2">
            <Input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="confirmPassword">
              Confirmation du mot de passe
            </Label>
          </div>
        </div>

        <div className="my-4 flex items-center gap-2">
          <Checkbox id="rememberMe" required />
          <Label htmlFor="rememberMe">
            J'accepte toutes les <span className="text-orange">conditions</span>{" "}
            et les{" "}
            <span className="text-orange">politiques de confidentialité</span>
          </Label>
        </div>

        <Button type="submit" hover="jumping" size="full">
          S'inscrire
        </Button>

        <div className="mt-4 text-center text-sm">
          <p>
            Vous avez déjà un compte ?{" "}
            <a href="/auth/login" className="text-orange">
              Connectez-vous
            </a>
          </p>
        </div>
      </form>
    </AuthLayout>
  );
};

export default SignUp;
