import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateSentiment, generateSummary, getFocusByID } from "../lib/focus";
import { getAllLanguages } from "../lib/languages";
import { getAllSocialMedias } from "../lib/socialMedias";
import { Focus, Language, SocialMedia } from "../types";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const FocusDetail: React.FC = () => {
  const location = useLocation();
  const { focusId } = location.state || {};
  const [focus, setFocus] = useState<Focus | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string | null>(
    null,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string | null>(null); // Store the generated summary
  const [sentiment, setSentiment] = useState<Record<string, any> | null>(null); // Store the sentiment as an object

  // Load focus details
  useEffect(() => {
    const loadFocus = async () => {
      if (!focusId) {
        setError("No ID provided");
        setLoading(false);
        return;
      }

      try {
        const data = await getFocusByID(focusId);
        setFocus(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focus.");
      } finally {
        setLoading(false);
      }
    };

    loadFocus();
  }, [focusId]);

  // Load available languages
  useEffect(() => {
    const loadLanguages = async () => {
      try {
        const data = await getAllLanguages();
        setLanguages(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch languages.");
      }
    };

    loadLanguages();
  }, []);

  // Load available social media platforms
  useEffect(() => {
    const loadSocialMedias = async () => {
      try {
        const data = await getAllSocialMedias();
        setSocialMedias(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch social media platforms.");
      }
    };

    loadSocialMedias();
  }, []);

  // Handle form submission for generating sentiment and summary
  const handleGenerateSentimentAndSummary = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!focusId || !selectedLanguage) {
      setError("Please select a language and a focus.");
      return;
    }

    try {
      const summaryResponse = await generateSummary(
        focusId,
        selectedLanguage,
        selectedSocialMedia || undefined,
      );

      const sentimentResponse = await generateSentiment(
        focusId,
        selectedSocialMedia || undefined,
      );

      setSummary(summaryResponse); // Store the generated summary
      setSentiment(sentimentResponse); // Store the sentiment object
    } catch (err: any) {
      setError(err.message || "Failed to generate sentiment or summary.");
    }
  };

  return (
    <div className="px-12 py-10">
      <h1 className="mb-6 text-3xl">{focus?.name}</h1>

      {error && <p className="text-red-500">{error}</p>}

      <form
        onSubmit={handleGenerateSentimentAndSummary}
        className="flex w-fit flex-col gap-3 rounded-3xl bg-green-light px-10 py-5 shadow-md"
      >
        <div className="flex items-center justify-between gap-4">
          <Label htmlFor="language" className="text-lg font-medium">
            Ajoute une langue
          </Label>
          <Select
            value={selectedLanguage || ""}
            onValueChange={(value) => setSelectedLanguage(value)}
          >
            <SelectTrigger className="w-36 rounded-2xl border border-green px-5 py-2 capitalize text-dark">
              <SelectValue placeholder="Langue" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Langue</SelectLabel>
                {languages.map((language) => (
                  <SelectItem
                    key={language.language_id}
                    value={language.language_id}
                  >
                    {language.language_name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center justify-between gap-4">
          <Label htmlFor="language" className="text-lg font-medium">
            Ajoute un réseau social
          </Label>

          <Select
            value={selectedSocialMedia || ""}
            onValueChange={(value) => setSelectedSocialMedia(value)}
          >
            <SelectTrigger className="w-36 rounded-2xl border border-green px-5 py-2 capitalize text-dark">
              <SelectValue placeholder="Réseau social" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Réseau Social</SelectLabel>
                {socialMedias.map((social) => (
                  <SelectItem
                    key={social.social_media_id}
                    value={social.social_media_id}
                  >
                    {social.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {/* Button to generate summary */}
        <Button
          type="submit"
          disabled={loading}
          className="mt-2 bg-orange"
          hover="jumping"
        >
          {loading ? "Loading..." : "Generate Summary and Sentiment"}
        </Button>
      </form>

      {summary && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold">Generated Summary:</h2>
          <p className="mt-3">{summary}</p>
        </div>
      )}

      {sentiment && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold">Sentiment Analysis:</h2>
          <p>Positive Ratio: {sentiment.positive_ratio}</p>
          <p>Negative Ratio: {sentiment.negative_ratio}</p>
          <p>Neutral Ratio: {sentiment.neutral_ratio}</p>
          <p>No Sentiment Ratio: {sentiment.no_sentiment_ratio}</p>
        </div>
      )}
    </div>
  );
};

export default FocusDetail;
