import { Focus } from "../types";
import axiosInstance from "./axiosInstance"; // Import the configured axiosInstance

// Function to get all focuses
export const getAllFocuses = async (): Promise<Focus[]> => {
  try {
    const response = await axiosInstance.get<Focus[]>("/focus");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focuses");
    } else {
      throw new Error("Failed to fetch focuses");
    }
  }
};

// Function to get all focuses by the current user
export const getAllFocusesByUser = async (): Promise<Focus[]> => {
  try {
    const response = await axiosInstance.get<Focus[]>("/user/focuses");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch user focuses",
      );
    } else {
      throw new Error("Failed to fetch user focuses");
    }
  }
};

export const getFocusByID = async (focus_id: string): Promise<Focus> => {
  try {
    const response = await axiosInstance.get<Focus>(`/focus/${focus_id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focus");
    } else {
      throw new Error("Failed to fetch focus");
    }
  }
};

// Function to add a focus to the current user
export const addFocusToUser = async (focus_id: string) => {
  try {
    const response = await axiosInstance.post("/focus/add_to_user", {
      focus_id, // Sending focus_id in the request body as JSON
    });

    return response.data; // Return the response data if needed
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to add focus to user",
      );
    } else {
      throw new Error("Failed to add focus to user");
    }
  }
};

export const createFocus = async (focusData: { name: string }) => {
  try {
    const response = await axiosInstance.post("/focus", focusData);
    return response.data; // Return the created focus data
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to create focus");
    } else {
      throw new Error("Failed to create focus");
    }
  }
};

export const generateSummary = async (
  focus_id: string,
  language_id: string,
  social_media_id?: string,
): Promise<string> => {
  try {
    const response = await axiosInstance.post("/focus/generate_summary", {
      focus_id,
      language_id,
      social_media_id: social_media_id || null, // Send null if undefined
    });
    return response.data; // Retourner le résumé généré (string)
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to generate summary",
      );
    } else {
      throw new Error("Failed to generate summary");
    }
  }
};

export const generateSentiment = async (
  focus_id: string,
  social_media_id?: string,
): Promise<Record<string, any>> => {
  try {
    const response = await axiosInstance.post("/focus/generate_ratio", {
      focus_id, // Add focus_id in the body
      social_media_id: social_media_id || null, // Add social_media_id in the body, defaulting to null if undefined
    });

    return response.data; // Return the object (dictionary) representing the sentiment analysis
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to generate sentiment analysis",
      );
    } else {
      throw new Error("Failed to generate sentiment analysis");
    }
  }
};
